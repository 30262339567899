import React from 'react';
import { HeroSection as HeroSectionUI } from '@decub8/ui';

import { IContentSection } from '@src/ts/interfaces';

export const stats_key_to_label = {
    average_ath_roi: 'Average ATH ROI',
    total_users: 'Users chosen Decubate',
    raised_and_distributed: 'Value raised & distributed',
};

export const HeroSection: React.FC<{
    section: IContentSection;
    className?: string;
}> = ({ section, className }) => {
    const desktop_image = section.images?.find((img) => img.name === 'desktop');
    const mobile_image = section.images?.find((img) => img.name === 'mobile');

    return (
        <HeroSectionUI
            className={className}
            images={{
                desktop: desktop_image?.url,
                mobile: mobile_image?.url,
            }}
            cards_data={section.data?.map(({ key, value }) => ({
                value: value,
                label: stats_key_to_label[key],
            }))}
            title={section.title}
        />
    );
};
